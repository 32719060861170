import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../environments/environment";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import * as CryptoJS from "crypto-js";

const ENCRYPTION_KEY: string = "89701bb10aa7913";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService) {}

  HandleLogin(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/employee/v1/login`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  StoreNotificationToken(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/common/v1/notification-token`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetEncryptionKey() {
    return ENCRYPTION_KEY;
  }

  GetCookieData() {
    if (this.cookieService.check("employee_user")) {
      let cookieData = this.cookieService.get("employee_user");
      const bytes = CryptoJS.AES.decrypt(cookieData, this.GetEncryptionKey());
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
    }
  }

  CheckUserAuthorization() {
    let userCookie = this.GetCookieData();
    let userType = localStorage.getItem("employee_account_type");
    let userEmail = localStorage.getItem("employee_email");

    if (!userCookie || !userType || !userEmail || userType != userCookie.userType || userEmail != userCookie.userEmail) {
      localStorage.removeItem("employee_access_token");
      localStorage.removeItem("employee_account_type");
      localStorage.removeItem("employee_email");
      localStorage.removeItem("employee_zendesk_token");
      localStorage.removeItem("employee_notification_token");
      localStorage.removeItem("employee_chatUserId");
      this.cookieService.delete("employee_user");
      this.router.navigate(["login"]);
      return false;
    }

    return true;
  }

  Logout() {
    localStorage.removeItem("employee_access_token");
    localStorage.removeItem("employee_account_type");
    localStorage.removeItem("employee_email");
    localStorage.removeItem("employee_zendesk_token");
    localStorage.removeItem("employee_notification_token");
    localStorage.removeItem("employee_chatUserId");
  }
}
