import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { HeaderService } from "./employee/layouts/header/header.service";

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate {
  user: any;

  constructor(private router: Router,private service: HeaderService) { }

  public canActivate(route: ActivatedRouteSnapshot){

      let user = localStorage.getItem('employee_account_type');
      localStorage.getItem('employee_access_token');
      if (user){
        this.service.authenticateUser().subscribe((res:any) => {
          if (res['data']['bIsQuestionnaireCompleted'] == false){
              this.router.navigate(['/employee-onboarding']);
          }
      },
        (err:any) => {
          console.log(err,"errror");
        })
       return true;
      } else {
        // console.log("redirect with route guard >>>>>>>>>>>>>>>>>>>>.");
        this.router.navigate(['/login']);
        return false;
      }
  }
}
