import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-design-system',
  templateUrl: './design-system.component.html'
})
export class DesignSystemComponent implements OnInit {

  skills: any = [
    {value: "1", viewValue: 'Frontend'},
    {value: "2", viewValue: 'Backend'},
    {value: "3", viewValue: 'Fullstack'},
  ];
  selectedSkills = "0";

  constructor() { }

  ngOnInit(): void {
  }

}
