import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RouteGuardService } from "./route-guard.service";
import { DesignSystemComponent } from "./design-system/design-system.component";
import { UnderConstructionComponent } from "./employee/under-construction/under-construction.component";
import { SiteConstructionComponent } from "./site-construction/site-construction.component";

const routes: Routes = [
  {
    path: "employee",
    canActivate: [RouteGuardService],
    loadChildren: () => import("./employee/employee.module").then((m) => m.EmployeeModule),
  },
  // { path: "design-system", component: DesignSystemComponent },
  // { path: "", component: SiteConstructionComponent },
  // { path: "**", redirectTo: "", pathMatch: "full" },
  { path: "login", loadChildren: () => import("./login/login.module").then((m) => m.LoginModule) },
  { path: "confirm-profile", loadChildren: () => import("./confirm-profile/confirm-profile.module").then((m) => m.ConfirmProfileModule) },
  { path: "forgot-password", loadChildren: () => import("./forgot-password/forgot-password.module").then((m) => m.ForgotPasswordModule) },
  { path: "profile-verification", loadChildren: () => import("./profile-verification/profile-verification.module").then((m) => m.ProfileVerificationModule) },
  { path: "profile-activation/:txEmailToken", loadChildren: () => import("./profile-activation/profile-activation.module").then((m) => m.ProfileActivationModule) },
  { path: "reset-password/:txEmailToken", loadChildren: () => import("./reset-password/reset-password.module").then((m) => m.ResetPasswordModule) },
  { path: "password-creation/:txEmailToken", loadChildren: () => import("./password-creation/password-creation.module").then((m) => m.PasswordCreationModule) },
  { path: "**", redirectTo: "login", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
