import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, APP_INITIALIZER } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";

// package imports
import { environment } from "./../environments/environment";
import { MaterialModule } from "./material.module";
import { MyInterceptor } from "./config/http.interceptor";
import { ToastrModule } from "ngx-toastr";
import { NgxUiLoaderModule, NgxUiLoaderConfig } from "ngx-ui-loader";
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";

// component imports
import { AppComponent } from "./app.component";
import { DesignSystemComponent } from "./design-system/design-system.component";

// Sentry.init({
//   dsn: environment.sentry.dsn,
//   integrations: [
//     new BrowserTracing({
//       tracingOrigins: ["https://employee.beta.rapidise.io"],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//   tracesSampleRate: 0.75,
// });

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsSize: 30,
  pbColor: "#5EC090",
  fgsColor: "#5EC090",
  overlayColor: "rgba(0, 0, 0, 0.1)",
  hasProgressBar: false,
};

@NgModule({
  declarations: [AppComponent, DesignSystemComponent],
  imports: [NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), BrowserModule, AppRoutingModule, HttpClientModule, BrowserAnimationsModule, MaterialModule, ToastrModule.forRoot(), RecaptchaV3Module],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: true,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.api_key,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
