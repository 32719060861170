import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import * as CryptoJS from "crypto-js";
import { environment } from "./../../environments/environment";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TranslateFormService } from "../utils/services/translate-form.service";
import { Router } from "@angular/router";
import { HeaderService } from "./../employee/layouts/header/header.service";
import Amplitude from "./../utils/amplitude";
import { CookieService } from "ngx-cookie-service";
import { NotificationService } from "./notification.service";

@Injectable()
export class MyInterceptor implements HttpInterceptor {
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private tf: TranslateFormService,
    private notifyService: NotificationService,
    private router: Router,
    private headerService: HeaderService,
    private amplitude: Amplitude,
    private cookieService: CookieService
  ) {}

  public message: any;
  public nonce: any = "35A90B73C39D97";
  public path: any;
  public privateKey: any = "4AF8AC8E124B6484BDDC5A9EB3D49";
  public secretKey: any = "PXGuKjNtjzLiyLRE0GzykvGcaZO5uWE0";
  public timestamp: any = 1615971782;
  public accounttype!: any;
  public chatToken!: any;

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //Generate Nonce
    const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    this.nonce = "";
    for (let i = 0; i < 8; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      this.nonce += chars.substring(rnum, rnum + 1);
    }
    //Generate TimeStamp
    this.timestamp = Math.floor(new Date().getTime() / 1000);

    //Generate TOken
    var generateHmacToken = CryptoJS.HmacSHA256(this.secretKey + this.timestamp + this.nonce, this.privateKey).toString(CryptoJS.enc.Hex);

    //Get Account Type
    let tempacctype = localStorage.getItem("employee_account_type");
    this.accounttype = this.tf.staticPush(tempacctype);
    let tempChatToken = localStorage.getItem("employee_chatUserId");
    this.chatToken = this.tf.staticPush(tempChatToken);

    const token = localStorage.getItem("employee_access_token");
    if (token) {
      if (httpRequest.url.includes('/api/common')) {
        const authReq = httpRequest.clone({
          withCredentials: true,
          headers: httpRequest.headers.set("nonce", this.nonce.toString()).set("timestamp", this.timestamp.toString()).set("token", generateHmacToken.toString()).set("authorization", token).set("accounttype", this.accounttype).set("Cache-Control", 'no-cache'),
        });
        httpRequest = authReq;
        this.ngxLoader.start();
      }else if (httpRequest.url.includes('/api/chat')) {
        //chat module integration
        if(this.chatToken){
          const authReq = httpRequest.clone({
            withCredentials: true,
            headers: httpRequest.headers.set("nonce", this.nonce.toString()).set("timestamp", this.timestamp.toString()).set("token", generateHmacToken.toString()).set("authorization", token).set("accounttype", this.accounttype).set("chattoken", this.chatToken).set("Cache-Control", 'no-cache'),
          });
            httpRequest = authReq;
        }
      }else{
        const authReq = httpRequest.clone({
          withCredentials: true,
          headers: httpRequest.headers.set("nonce", this.nonce.toString()).set("timestamp", this.timestamp.toString()).set("token", generateHmacToken.toString()).set("authorization", token).set("accounttype", this.accounttype),
        });
        httpRequest = authReq;
        this.ngxLoader.start();
      }
    } else {
      if (httpRequest.url.includes('/api/common')) {
        const authReq = httpRequest.clone({
          withCredentials: true,
          headers: httpRequest.headers.set("nonce", this.nonce.toString()).set("timestamp", this.timestamp.toString()).set("token", generateHmacToken.toString()).set("Cache-Control", 'no-cache'),
        });
        httpRequest = authReq;
        this.ngxLoader.start();
      }else{
        const authReq = httpRequest.clone({
          withCredentials: true,
          headers: httpRequest.headers.set("nonce", this.nonce.toString()).set("timestamp", this.timestamp.toString()).set("token", generateHmacToken.toString()),
        });
        httpRequest = authReq;
        this.ngxLoader.start();
      }
    }
    return next.handle(httpRequest).pipe(
      tap(
        (event) => this.handleResponse(httpRequest, event),
        (error) => this.handleError(httpRequest, error)
      )
    );
  }

  handleResponse(httpRequest: HttpRequest<any>, event: any) {
    if (event instanceof HttpResponse) {
      this.ngxLoader.stop();

      if (event.body && event.body.code == 401) {
        this.ngxLoader.stop();
        this.headerService.Logout();
        this.amplitude.Reset();
        localStorage.removeItem("employee_access_token");
        localStorage.removeItem("employee_account_type");
        localStorage.removeItem("employee_email");
        localStorage.removeItem("employee_zendesk_token");
        localStorage.removeItem("employee_notification_token");
        localStorage.removeItem("employee_chatUserId");
        this.cookieService.delete("employee_user");
        this.router.navigate(["/login"]);
      }
    }
  }

  handleError(httpRequest: HttpRequest<any>, error: any) {
    if (error.status == 0) {
      this.ngxLoader.stop();
      setTimeout(() => {
        window.location.href = environment.baseUrlLogin;
      }, 1000);
    }
    if (error.status == 401 || error.status == 404 || error.status == 500) {
      this.ngxLoader.stop();
      localStorage.removeItem("employee_access_token");
      localStorage.removeItem("employee_account_type");
      localStorage.removeItem("employee_email");
      localStorage.removeItem("employee_zendesk_token");
      localStorage.removeItem("employee_notification_token");
      localStorage.removeItem("employee_chatUserId");
      window.location.href = environment.baseUrlLogin;
    }else{
      this.ngxLoader.stop();
      this.notifyService.showError("Something went wrong!", "");
    }
  }
}
