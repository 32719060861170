import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private toastr: ToastrService) {}

  showSuccess(message: any, title: any) {
    this.toastr.success(title, message, {
      timeOut: environment.dis_alert_timeout,
      progressBar: true,
    });
  }

  showError(message: any, title: any) {
    this.toastr.error(title, message, {
      timeOut: environment.dis_alert_timeout,
      progressBar: true,
    });
  }

  showInfo(message: any, title: any) {
    this.toastr.info(title, message, {
      timeOut: environment.dis_alert_timeout,
      progressBar: true,
    });
  }

  showWarning(message: any, title: any) {
    this.toastr.warning(title, message, {
      timeOut: environment.dis_alert_timeout,
      progressBar: true,
    });
  }
}
