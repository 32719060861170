import { Component, OnInit } from "@angular/core";
import { AppService } from "./app.service";
import { LoginService } from "./login/login.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  title = "Rapidise - Employee";

  constructor(private appService: AppService, private loginService: LoginService) {
    let accessToken = localStorage.getItem("employee_access_token");
    
    if (accessToken) {
      this.CheckUserAuthorization();
    }
  }

  ngOnInit() {
    this.CreateRequiredHeadTags();
  }

  CreateRequiredHeadTags() {
    this.appService.CreateLinkTagsForGoogleFont();
    this.appService.CreateScriptTagForAmplitude();
    this.appService.CreateScriptTagForZendesk();
    this.appService.CreateScriptTagForGoogleTag();
  }

  CheckUserAuthorization() {
    this.loginService.CheckUserAuthorization();
  }
}
