import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  public innerNav = new Subject<any[]>();

  // Create an observable to watch the subject and send out a stream of updates (You will subscribe to this to get the update stream)
  public innerNav$ = this.innerNav.asObservable(); //Has a $

  // ------ How to update the subject ---------
  // Create a method that allows you to update the subject being watched by observable
  public updateInnerNav(list: any) {
    this.innerNav.next(list);
  }

  public scrollEnd = new Subject<boolean>();

  // Create an observable to watch the subject and send out a stream of updates (You will subscribe to this to get the update stream)
  public scrollEnd$ = this.scrollEnd.asObservable(); //Has a $

  // ------ How to update the subject ---------
  // Create a method that allows you to update the subject being watched by observable
  public updateScrollSubject(newScrollEnd: boolean) {
    this.scrollEnd.next(newScrollEnd);
  }

  constructor(private http:HttpClient) { }

  authenticateUser(){
    return this.http.get(`${environment.baseUrl}/common/v1/login-user-information`);
  }

  logoutAPI(data:any){
    return this.http.post(`${environment.baseUrl}/common/v1/Logout`,data);
  }

  Logout(){
    localStorage.removeItem("employee_access_token");
    localStorage.removeItem("employee_account_type");
    localStorage.removeItem("employee_email");
    localStorage.removeItem("employee_zendesk_token");
    localStorage.removeItem("employee_notification_token");
    localStorage.removeItem("employee_chatUserId");
  }

  GetNotification(data: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/common/v1/notification/${data.iUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  NotificationChecked(data: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/common/v1/notification/checked`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetInterviewCount() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/employee/v1/get-interview-count`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetLocalTimeZone(){
    const localtimezone=Intl.DateTimeFormat().resolvedOptions().timeZone;
    return localtimezone;
  }

}
